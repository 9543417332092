<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Countries</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Countries
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('country', 'create')"
                      @click="createState()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4" sm="6">
                  <v-text-field clearable dense
                                label="Lookup by Name" outlined v-model="search.title"
                                v-on:keyup.enter="searchCountry"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-select clearable dense
                            :items="items"
                            item-text="name"
                            item-value="value"
                            label="Search By" outlined v-model="search.flag"></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-btn
                      :loading="isLoading"
                      @click="searchCountry"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <v-skeleton-loader
                type="card"
                v-if="isLoading">
            </v-skeleton-loader>
            <v-row v-else>
              <v-col cols="3" v-for="(item, index) in sortedItems" :key="index">
                <v-card>
                  <v-card-title>
                    <div class="text-center">
                      <v-icon class="mx-auto mr-2" large>
                        {{ `em em-flag-${item.iso_2.toLowerCase()}` }}
                      </v-icon>
                    </div>
                    {{ item.title }}
                    <v-spacer></v-spacer>
                    <template>
                      <b-dropdown
                          no-caret
                          no-flip
                          right
                          size="sm"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          variant="link"
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text class="navi-item" tag="div"  >
                            <a @click="toggleOperationCountry(item)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon color="darken-2"
                                                                            :color="item.is_operating_country?'green':'red'">fas fa-circle</v-icon>
                                                                </span>
                              <span
                                  class="navi-text">{{
                                  item.is_operating_country ? 'Disable' : 'Enable'
                                }} Operating Country </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text class="navi-item" tag="div" >
                            <a @click="toggleActiveStatus(item)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon color="darken-2"
                                                                            :color="item.is_active?'green':'red'">fas fa-circle</v-icon>
                                                                </span>
                              <span
                                  class="navi-text">{{
                                  item.is_active ? 'Disable' : 'Enable'
                                }} Country Status</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text class="navi-item" tag="div" >
                            <a @click="toggleEligibleForApply(item)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon
                                                                        color=" darken-2"
                                                                        :color="item.eligible_for_apply?'green':'red'">fas fa-circle</v-icon>
                                                                </span>
                              <span
                                  v-text="item.eligible_for_apply ?'Disable eligibility to apply':'Enable eligibility to apply'"
                                  class="navi-text"></span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text class="navi-item" tag="div">
                            <a @click="manageCurrencyAndPaymentMethod(item)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon color="darken-2"
                                                                    >fas fa-dollar-sign</v-icon>
                                                                </span>
                              <span
                                  class="nav-text"> Manage Currency and Payment Methods </span>
                            </a>
                          </b-dropdown-text>
<!--                          <b-dropdown-text class="navi-item" tag="div">-->
<!--                            <a @click="manageCountryService(item)" class="navi-link">-->
<!--                              <span class="navi-icon">-->
<!--                                  <v-icon color="darken-2"-->
<!--                                  >fas fa-gear</v-icon>-->
<!--                              </span>-->
<!--                              <span class="nav-text"> Manage Service </span>-->
<!--                            </a>-->
<!--                          </b-dropdown-text>-->
                          <b-dropdown-text class="navi-item" tag="div">
                            <a @click="manageDliNumber(item)" class="navi-link">
                              <span class="navi-icon">
                                  <v-icon color="blue darken-2">fas fa-cogs</v-icon>
                              </span>
                              <span class="navi-text">Manage DLI Number</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text class="navi-item" tag="div">
                            <a @click="editState(item)" class="navi-link">
                              <span class="navi-icon">
                                  <v-icon color="orange darken-2">fas fa-edit</v-icon>
                              </span>
                              <span class="navi-text">Manage State</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('country', 'delete')">
                            <a @click="deleteState(item.id)" class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="red">fas fa-trash</v-icon>
                                                            </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>

                  </v-card-title>
                  <v-card-subtitle>
                    {{ item.capital }}, {{ item.prefix }}
                  </v-card-subtitle>
                  <v-card-text>
                    <div class="d-flex justify-content-between">
                      <strong>Institution</strong>
                      <span>{{ item.instutions || 0 }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <strong>Programs</strong>
                      <span>{{ item.programs || 0 }}</span>
                    </div>
                  </v-card-text>

                </v-card>

              </v-col>
              <v-col cols="12" v-if="sortedItems.length==0">
                No Data Found
              </v-col>
            </v-row>


            <b-pagination
                :disabled="isLoading"
                :per-page="perPage"
                :total-rows="total"
                @input="getCountries"
                class="pull-right mt-7"
                first-number
                last-number
                v-if="sortedItems.length > 0" v-model="page"
            ></b-pagination>

          </div>
        </div>
      </div>
      <create-or-update @refresh="getCountries" ref="create-or-update"></create-or-update>
      <manage-dli-number ref="manage-dli-number" @refresh="getCountries"></manage-dli-number>
      <manage-currency-and-payment-methods ref="manage-currency-and-payment-method" @refresh="getCountries"></manage-currency-and-payment-methods>
      <manage-country-service ref="manage-country-service" @refresh="getCountries"></manage-country-service>
    </div>
  </v-app>
</template>
<script>
import CountryService from "@/services/country/CountryService";
import CreateOrUpdate from "./CreateOrUpdate";
import ManageDliNumber from "@/view/pages/view/country/ManageDLINumber";
import ManageCurrencyAndPaymentMethods from "@/view/pages/view/country/ManageCurrencyAndPaymentMethod";
import ManageCountryService from "@/view/pages/view/country/ManageCountryService";

const country = new CountryService();
export default {
  components: {
    ManageCountryService,
    ManageCurrencyAndPaymentMethods,
    ManageDliNumber,
    CreateOrUpdate,
  },
  data() {
    return {
      isLoading: false,
      search: {
        title: '',
        flag: "operating_country"
      },
      countries: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      items: [
        {name: 'In-active', value: 'in-active'},
        {name: 'Active', value: 'active'},
        {name: 'Operating Country', value: 'operating_country'},
        {name: 'Eligible for apply', value: 'eligible_for_apply'},
      ],
      status: [
        {name: 'Status', value: ''},
        {name: 'Active', value: 'active'},
        {name: 'Inactive', value: 'in_active'},
      ],

    }
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    sortedItems() {
      const list = this.countries.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    }
  },
  methods: {
    getCountries() {
      this.isLoading = true;
      country
          .getAllCountry(this.search)
          .then(response => {
            this.countries = response.data.data;
            this.isLoading = false;
          })
          .catch((err) => {
            // console.log(err);
          });
    },

    searchCountry() {
      this.getCountries();
    },

    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },

    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    createOrUpdateState(stateId) {
      if (stateId == null)
        this.$router.push({
          name: "state-create"
        });
      else
        this.$router.push({
          name: 'state-edit',
          params: {stateId: stateId}
        })
    },

    manageCurrencyAndPaymentMethod(item) {
      this.$refs['manage-currency-and-payment-method'].showDialog(item);
    },

    manageDliNumber(item) {
      this.$refs['manage-dli-number'].openDialog(item);
    },

    manageCountryService(item) {
      this.$refs['manage-country-service'].showDialog(item);
    },

    createState() {
      this.$refs['create-or-update'].createState();
    },

    editState(item) {
      this.$router.push({
        name: 'state',
        params: {
          'country_id': item.id
        }
      })
    },

    toggleActiveStatus(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            country
                .toggleIsActive(item.id)
                .then((response) => {
                  this.getCountries();
                  this.$snotify.success("Country Operating Successfully Toggled");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    toggleOperationCountry(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            country
                .toggleOperating(item.id)
                .then((response) => {
                  this.getCountries();
                  this.$snotify.success("Country Operating Successfully Toggled");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    toggleEligibleForApply(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            country
                .toggleEligibleForApply(item.id)
                .then((response) => {
                  this.getCountries();
                  this.$snotify.success("Country eligibility to apply changed successfully");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    deleteState(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            state
                .delete(item)
                .then((response) => {
                  this.getCountries();
                  this.$snotify.success("Country Successfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
<style>
@import 'https://emoji-css.afeld.me/emoji.css';
</style>