<template>
  <v-dialog max-width="600" v-model="dialog">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          Manage Country Service
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn @click="refreshDialog" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field  v-model="country.trading_name" outlined dense label="Trading name"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="country.cricos_provider_code" outlined dense label="CRICOS Provider code"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="country.teqsa_code" outlined dense label="TESSA code"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="country.rto_code" outlined dense label="RTO code"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            @click="refreshDialog">
          Close
        </v-btn>

        <v-btn
            :loading="loading"
            class="btn btn-primary"
             @click="updateCountry()"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryService from "@/services/country/CountryService";

const countryService = new CountryService();

export default {
  name: "ManageCountryService",
  data() {
    return {
      dialog: false,
      loading: false,
      country: {},
    }
  },
  methods: {
    showDialog(country) {
      this.dialog = true
      this.country = country;
    },

    refreshDialog() {
      this.country = {};
      this.dialog = false
      this.$emit('refresh');
    },

    updateCountry() {
      this.loading = true;
      if (this.country.payment_gateways && this.country.payment_gateways.length > 0)
        countryService.update(this.country.id, this.country).then(response => {
        this.loading = false
        this.refreshDialog();
        this.$snotify.success("Something went wrong please try again later")
      }).catch(() => {
        this.loading = false
        this.$snotify.error("Something went wrong please try again later")
      })
    }
  }
}
</script>