<template>
  <v-dialog max-width="600" v-model="dialog">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          Manage Country
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn @click="refreshDialog" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select multiple v-model="country.payment_gateways" :items="payment_gateways" item-value="value"
                        item-text="title" outlined dense label="Payment Gateways"></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="country.symbol" outlined dense label="Currency Symbol"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="mr-2" text dark
        medium
            @click="refreshDialog">
          Close
        </v-btn>
        <v-btn :loading="loading"
                class="btn btn-primary"
                medium
               text
               @click="updateCountry()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryService from "@/services/country/CountryService";

const countryService = new CountryService();

export default {
  name: "ManageCurrencyAndPaymentMethods",
  data() {
    return {
      dialog: false,
      loading: false,
      country: {},
      payment_gateways: [
        {title: 'Bank', value: 'bank'},
        {title: 'Khalti', value: 'khalti'},
        {title: 'Card', value: 'card'},
        {title: 'Esewa', value: 'esewa'},
        {title: 'Connect IPS', value: 'connectips'},
        {title: 'Paypal', value: 'paypal'},
        {title: 'Cash', value: 'cash'},
      ]
    }
  }, methods: {
    showDialog(country) {
      this.dialog = true
      this.country = country;
    },
    refreshDialog() {
      this.country = {};
      this.dialog = false
      this.$emit('refresh');
    },

    updateCountry() {
      this.loading = true;
      if (this.country.payment_gateways && this.country.payment_gateways.length > 0)
        this.country.payment_gateways = this.country.payment_gateways.join(',')
      countryService.update(this.country.id, this.country).then(response => {
        this.loading = false
        this.refreshDialog();
        this.$snotify.success("Something went wrong please try again later")
      }).catch(error => {
        this.loading = false
        this.$snotify.error("Something went wrong please try again later")
      })
    }
  }
}
</script>

<style scoped>

</style>