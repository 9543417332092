<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          Add Operating Country
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                      :loading="countryLoading"
                      :error="$v.country.$error"
                      :filter="customFilter"
                      :items="countries"
                      @change="changeCountry()"
                      dense
                      item-text="name"
                      item-value="id"
                      label="Country "
                      outlined
                      prepend-inner-icon="fas fa-globe-americas"
                      return-object
                      v-model="country"
              >
                <template v-slot:item="slotProps">
                  <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{slotProps.item.title}}
                </template>
                <template v-slot:selection="data">
                  <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{data.item.title}}
                </template>
              </v-autocomplete>

            </v-col>


          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
            v-if="checkIsAccessible('country', 'create') || checkIsAccessible('country', 'edit')">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import StateService from "@/services/country/state/StateService";
import CountryService from "@/services/country/CountryService";
const country=new CountryService();

import {required} from "vuelidate/lib/validators";
const state =new StateService();
export default {
  validations:{
      country:{required},
  },
  data() {
    return {
      title: 'Create',
      dialog: false,
      countryLoading: false,
      edit: false,
      loading: false,
      countries:[],
      errors:[],
      country:null,
    }
  },
  methods: {
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          })
    },
    toogleOperationCountry() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            country
                    .toggleOperating(this.country.id)
                    .then((response) => {
                      this.closeDialog();
                      this.$emit('refresh');
                      this.$snotify.success("Country Operating Successfully Toggled");
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      this.$snotify.error("Oops something went wrong");
                    });
          }
        },
      });
    },

    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.state={
        name:'',
        prefix:'',
        country_id:14,
      };
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
    createState() {

      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editState(item) {
      this.openDialog();
      this.edit = true;
      this.state = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
      this.toogleOperationCountry();
      }
    },
    _create: function () {
      this.loading = true;
      state
          .create(this.state)
          .then(response => {
            this.$snotify.success("State created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      state
          .update(this.state.id, this.state)
          .then(response => {
            this.$snotify.success("State updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },

    stateDetail() {
      if(this.stateId != null || this.stateId !=undefined ){
        state
            .show(this.stateId)
            .then(response=> {

              this.state = response.data.state;

            })
      }
    }
  },
  mounted() {
    this.getCountries();
    this.stateDetail();
  },
  computed:{
    stateId () {
      return this.$route.params.stateId;
    },
    titleText(){
      if(this.stateId!=null || this.stateId!=undefined)
        return this.title="Edit";
      else
        return this.title="Create New"
    }
  }
}
</script>