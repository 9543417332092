<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          Update DLI Number
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  type="number"
                  v-model="country.dli_number"
                  :error="$v.country.dli_number.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  DLI Number <span class="text-danger">*</span>
                </template>

              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--        v-if="checkIsAccessible('country', 'create') || checkIsAccessible('country', 'edit')"-->
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import CountryService from "@/services/country/CountryService";
import {required} from "vuelidate/lib/validators";

const country = new CountryService();

export default {
  name: "ManageDliNumber",
  validations:{
    country:{
      dli_number: {required}
    },
  },
  data() {
    return {
      title: 'Update',
      dialog: false,
      edit: false,
      loading: false,
      errors:[],
      country: {},
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog(country) {
      this.dialog = true;
      this.country = country;
    },
    resetForm(){
      this.state={
        name:'',
        prefix:'',
        country_id:14,
      };
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.update();
      }
    },
    update: function () {
      this.loading = true;
      country
          .update(this.country.id, this.country)
          .then(response => {
            this.$snotify.success("Country updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
  },
}
</script>